import { useState } from 'react'
import moment from 'moment'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import FaceIcon from '@mui/icons-material/Face';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { blue, red, grey, teal, amber } from '@mui/material/colors';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

/** 
 * 
 * use yarn dev to complie
 * 
 * scripts that needed in package.json for heroku deploy 
 *  "scripts": {
    "dev": "react-scripts start",
    "start": "serve -s build",
    "build": "react-scripts build",
    "test": "react-scripts test --env=jsdom",
    "eject": "react-scripts eject",
    "heroku-postbuild": "npm run build"
  },
 * 
 */

const theme = createTheme( {
  palette: {
    primary: {
      main: blue[ 700 ],
      icon: blue[ 300 ],
    },
    secondary: {
      main: '#f44336',
    },
    warning: {
      main: red[ 900 ],
    },
    text: {
      danger: red[ 600 ],
      subtext: grey[ 500 ],
    },
    status: {
      waiting: amber[ 600 ],
      paid: teal[ 500 ],
      cancel: grey[ 400 ],
      send: blue[ 600 ],
    }
  },
} );


function Copyright ( props )
{
  return (
    <Typography variant="body2" color="text.secondary" align="center" { ...props }>
      { 'Copyright © ' }
      <Link color="inherit" href="https://twitter.com/iamberbar">
        เบ๊อะบ๊ะ
      </Link>{ ' ' }
      { new Date().getFullYear() }
      { '.' }
    </Typography>
  );
}

function googleImage ( id )
{
  return ( 'https://drive.google.com/uc?id=' + id )
}
function statusColor ( status )
{
  var pickColor = "";
  switch ( status )
  {
    case "รอชำระ":
      pickColor = "status.waiting";
      break;
    case "ชำระเงินแล้ว":
      pickColor = "status.paid";
      break;
    case "ยกเลิกรายการ":
      pickColor = "status.cancel";
      break;
    case "จัดส่งเสร็จสิ้น":
      pickColor = "status.send";
      break;

    default:
      pickColor = "status.cancel";
      break;
  }
  return ( pickColor )
}

const textLable = {
  userText: 'Email',
  passText: '- last 4 digis'

}


function App ()
{

  const apiURL = 'https://script.google.com/macros/s/AKfycbxTgEymGHgS31KeeaIgQ6HkfNETcY_W44MpccjeTs14HBLcusgumkUV-6aJ3yzvglNf-w/exec'

  const [ account, setAccount ] = useState( '' );
  const [ password, setPassword ] = useState( '' );
  const [ orderData, setOrderData ] = useState();
  const [ errorData, setErrorData ] = useState();
  const [ login, setLogin ] = useState( false );


  /* thailand post tracking code
  const [ datas, setDatas ] = useState( null );
  const token = "M#YyU$EqClHZB#LYIsV!UMVaVNWZF9U$QbV7Z9WRM2GWX_GSZ~XUPNCVIZN;I&QWX;EJLATHE_YNBRQ6FYZKUQOMJdPrMsWwT;QT"
  const [ trackInfo, setTrackInfo ] = useState();
  const [ checkingTrack, setCheckingTrack ] = useState( '' );
*/
  // useEffect (()=>{
  //   axios.post(apiURL,account).then ( (respond) => {

  //     console.log(respond);
  //     console.log(setDatas(respond));
  //   })

  // });

  /* 
    const getTrackingData = ( e ) =>
    {
  
      // console.log(e.target.value);
  
      const track = e.target.value;
      setCheckingTrack( track );
      setTrackInfo( '' );
      let shippingStatus = [];
  
      axios.post( 'https://trackapi.thailandpost.co.th/post/api/v1/authenticate/token', {},
        {
          headers: {
            Authorization: "Token " + token,
            'Content-Type': 'application/json',
          }
        } ).then( ( response ) =>
        {
          // console.log(response.data);  // show get token correcty
  
  
          axios.post( 'https://trackapi.thailandpost.co.th/post/api/v1/track', {
            status: "all",
            language: "TH",
            barcode: [ track ]
          },
            {
              headers: {
                Authorization: "Token " + response.data.token,
                'Content-Type': 'application/json',
              }
            } ).then( ( response ) =>
            {
  
              // console.log(response.data);
              shippingStatus = Object.values( response.data.response.items )[ 0 ];
              let trackAllStatus = "";
              for ( let i = 0; i < shippingStatus.length; i++ )
              {
                trackAllStatus += "" +
                  "" + moment( new Date( shippingStatus[ i ].status_date ) ).format( 'DD-MM-YYYY | HH:mm น.' )
                  + " | ที่ทำการ : " + shippingStatus[ i ].location
                  + " |  รหัสไปรษณีย์ : " + shippingStatus[ i ].postcode
                  + " | สถานะ : " + shippingStatus[ i ].status_description
                  + '\n'
              }
  
              setTrackInfo( trackAllStatus );
            } );
  
  
        } );
  
    }
  */

  const submitHandler = ( e ) =>
  {
    setErrorData( false )
    e.preventDefault();
    /* Thailand post Tracking code
    setCheckingTrack( '' );
     setTrackInfo( '' );
     */
    // console.log( "submit : " + account );
    const getData = "?account=" + account
      + "&key=" + password;

    axios.post( apiURL + getData ).then( ( response ) =>
    {
      // console.log(response.data);
      // setDatas( response.data );



      if ( !response.data.error )
      {
        const Data = response.data.data;
        // console.log( Data );
        const order = {

          date: Data[ 0 ].date,
          order: Data[ 0 ].order,
          email: Data[ 0 ].email,
          book1: Data[ 0 ].book1,
          book2: Data[ 0 ].book2,
          name: Data[ 0 ].name,
          address: Data[ 0 ].address,
          contact: Data[ 0 ].contact,
          total: Data[ 0 ].total,
          status: Data[ 0 ].status,
          remarks: Data[ 0 ].remarks,
          noted: Data[ 0 ].noted,
        }
        // console.log( order );
        setOrderData( order );
        setLogin( true );
      } else
      {
        setErrorData( response.data.error )
      }


    } );

  }
  const clearData = ( e ) =>
  {

    setOrderData( {} );
    setLogin( false );
  }


  const fillZero = ( data ) =>
  {
    var size = data.toString().length;
    while ( size < 4 )
    {
      data = "0" + data;
      size++;
    }
    return data;
  }





  return (
    <>


      <ThemeProvider theme={ theme }>
        <Grid container component="main" sx={ { height: '100vh' } }>
          <CssBaseline />
          { login ? <>
            <Grid
              item
              xs={ false }
              sm={ 4 }
              md={ 7 }
              sx={ {
                backgroundImage: 'url(https://drive.google.com/uc?id=1zKaKGeS-IKJiGs5PtUeuUIP1d62RlutH)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: ( t ) =>
                  t.palette.mode === 'light' ? t.palette.grey[ 50 ] : t.palette.grey[ 900 ],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              } }
              alignContent="center"
              alignItems={ "center" }
            >





              <Box width="80%" component={ Paper } sx={ {
                mt: 8,
                mb: 2,
                mx: 8,
                py: 2,
                px: 4,
                display: 'flex',
                flexDirection: 'column',
              } } >
                <Typography component="h2" variant="h6" sx={ { my: 2 } } alignSelf="center" >
                  รายการ
                </Typography>

                <Box width="80%" sx={ { my: 1, } } >
                  <Grid container>
                    <Grid item xs>
                      วันที่สั่ง : { moment( new Date( orderData.date ) ).format( 'DD-MM-YYYY | HH:mm น.' ) }
                    </Grid>

                  </Grid>
                </Box>

                { orderData.book1 !== 0 ?

                  <Card sx={ { minWidth: "100%", m: 1 } }>
                    <CardMedia
                      component="img"
                      height="200"
                      image={ googleImage( "1w2gGpQQg1pn36wjEkRAMxwl3gtfWUMFc" ) }
                      alt="Complete Edition Box set"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Complete Edition Box set
                      </Typography>

                      <Grid container>
                        <Grid item xs>
                          <Typography variant="body2" color="text.secondary">
                            ราคา 1,190 บาท
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" color="text.danger">
                            จำนวน : { orderData.book1 } ชุด
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  : <></> }
                { orderData.book2 !== 0 ?

                  <Card sx={ { minWidth: "100%", m: 1 } }>

                    <CardMedia
                      component="img"
                      height="200"
                      image={ googleImage( "1rSY7YydVGlBV-dhtat3qFcAS7pARC0-V" ) }
                      alt="Tengu + Addition Jacket"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Tengu + Addition Jacket & Box Set
                      </Typography>
                      <Grid container>
                        <Grid item xs>
                          <Typography variant="body2" color="text.secondary">
                            ราคา 590 บาท
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" color="text.danger">
                            จำนวน : { orderData.book2 } ชุด
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  : <></> }


                <Box sx={ { minWidth: "100%", m: 1 } }>

                  <Grid container>
                    <Grid item xs>
                      สถานะ
                      <Typography variant="h6" color={ statusColor( orderData.status ) }>
                        { orderData.status }
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" component="div">
                        รวมต้องชำระ
                      </Typography>
                      <Typography variant="h6" >
                        { orderData.total } บาท
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

              </Box>
              { orderData.remarks ? <>
                <Box width="80%" component={ Paper } sx={ {
                  my: 2,
                  mx: 8,
                  py: 2,
                  px: 4,
                  display: 'flex',
                  flexDirection: 'column',
                } } >
                  <Grid container>
                    <Grid item xs={ 7 } sx={ {
                      mr: 4,
                    } }>
                      <Typography variant="caption" component="div"> <b>Remarks</b> :</Typography>
                      <Typography variant="caption" component="div">
                        { orderData.remarks }
                      </Typography>
                    </Grid>
                    <Grid item xs={ 4 } >
                      { orderData.noted ? <>
                        <Typography variant="caption" component="div" >
                          <img src={ googleImage( "1ov1XEm3rJ4DLpsfxulEfiqKz6r6Bgp1N" ) } alt="berbar" height={ 50 } />
                          <b>เบ๊อะบ๊ะบันทึก</b> :</Typography>
                        <Typography variant="caption" component="div" >
                          { orderData.noted }
                        </Typography></>
                        : <></> }
                    </Grid>
                  </Grid>
                </Box>

              </> : <></> }
            </Grid>

          </> :
            <Grid
              item
              xs={ false }
              sm={ 4 }
              md={ 7 }
              sx={ {
                backgroundImage: 'url(https://drive.google.com/uc?id=1zKaKGeS-IKJiGs5PtUeuUIP1d62RlutH)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: ( t ) =>
                  t.palette.mode === 'light' ? t.palette.grey[ 50 ] : t.palette.grey[ 900 ],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              } }
            />
          }


          <Grid item xs={ 12 } sm={ 8 } md={ 5 } component={ Paper } elevation={ 6 } square>
            <Box
              sx={ {
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              } }
            >


              { login ? <>

                <Avatar sx={ { m: 1, bgcolor: 'primary.main' } }>
                  <VerifiedUserIcon />
                </Avatar>
                <Typography component="h1" variant="h5" sx={ { mt: 4 } }>
                  รหัสสั่งจอง : { fillZero( orderData.order ) }

                </Typography>

                <Box width="80%" sx={ { mt: 4, } } >
                  <Typography component="h2" variant="h6" sx={ { mt: 6 } } >
                    ข้อมูลผู้สั่ง
                  </Typography>
                  <Box sx={ { my: 2, } }>

                    <Grid container>
                      <Grid item xs>
                        <FaceIcon sx={ { color: 'primary.main', mr: 0.2, my: -0.8 } } /> { orderData.name }
                      </Grid>
                      <Grid item>
                        <EmailIcon sx={ { color: 'primary.icon', mr: 0.2, my: -0.8 } } /> { orderData.email }
                        <br />
                        <AlternateEmailIcon sx={ { color: 'primary.icon', mr: 0.2, my: -0.8 } } /> { orderData.contact ? orderData.contact : " - " }
                      </Grid>
                    </Grid>

                  </Box>

                  <Typography component="h2" variant="h6" sx={ { mt: 6 } } >
                    ที่อยู่จัดส่ง
                  </Typography>
                  <Box sx={ { my: 2, whiteSpace: 'pre-line' } } > { orderData.address }</Box>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={ { mt: 6, mb: 2 } }
                    onClick={ clearData }
                  >
                    ล้างข้อมูล
                  </Button>
                </Box>



                <Typography component="h2" variant="h6" sx={ { mt: 4, mb: 2 } } >
                  ข้อมูลการชำระเงิน
                </Typography>
                <img src={ googleImage( "1-Pg_Tt8bstbBLAfnQdO9MBjLjB5jwIEZ" ) }
                  width="60%"
                  alt="payment" />
                <Typography variant="caption" component="div" sx={ { my: 1, textAlign: "center" } } alignSelf="center" >
                  044-2-80647-0
                  <br /> ธนาคารกสิกร
                  <br />Pimpisut Siriaksornsard
                  <br /> (พิมพิศุทธิ์ ศิริอักษรสาสน์)
                </Typography>


              </>


                :




                // not login
                <>
                  <Avatar sx={ { m: 1, } }>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5" sx={ { mt: 4 } }>
                    ตรวจสอบรายการสั่งซื้อ
                  </Typography>


                  <Box width="80%" component="form" sx={ { mt: 6, } } onSubmit={ submitHandler }>


                    <Box sx={ { display: 'flex', alignItems: 'flex-end', mt: 3, mb: 2 } }>
                      <EmailIcon color='primary' sx={ { color: 'primary', mr: 1, my: 0.5 } } />
                      <TextField
                        required
                        fullWidth
                        id="input-user"
                        label={ textLable.userText }
                        variant="standard"
                        onChange={ ( e ) => setAccount( e.target.value ) } />

                    </Box>

                    <Box sx={ {
                      display: 'flex', alignItems: 'flex-end', my: 4
                    } }>
                      <PhoneAndroidIcon
                        color='disabled'
                        sx={ { color: 'disabled', mr: 1, my: 0.5 } } />
                      <TextField
                        required
                        fullWidth
                        id="input-key"
                        type='password'
                        label={ textLable.passText }
                        variant="standard"
                        onChange={ ( e ) => setPassword( e.target.value ) } />
                    </Box>


                    <Typography variant="caption" component="div" align="center" color={ "text.subtext" }>
                      รหัสการเข้าถึง คือเลข 4 ตัวท้ายของหมายเลขโทรศัพท์ที่กรอกไว้
                    </Typography>

                    { errorData ?
                      <>
                        <Typography variant="caption" component="div" align="center" color={ "text.danger" } sx={ { mt: 5, } }>
                          ไม่พบข้อมูลที่ค้นหา
                          <br />
                          กรุณาลองตรวจสอบข้อมูลอีกครั้ง
                        </Typography>

                        <Typography variant="caption" component="div" align="center" color={ "text.subtext" } sx={ { mt: 1, } }>
                          หรือติดต่อแจ้ง
                          <Link href="https://docs.google.com/spreadsheets/d/15k3odNoTQ_PWJlR-P7c2lSA46oT-Y4FNLBFt6vQ6xQo/edit#gid=0" variant="caption">
                            ลำดับการสั่งซื้อ
                          </Link>และชื่อผู้จองเข้ามาที่
                          <Link href="https://twitter.com/berbarbooks" variant="caption">
                            @berbarbooks
                          </Link>

                        </Typography>
                      </> : <></>
                    }

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={ { mt: 7, mb: 2 } }
                    >
                      ตรวจสอบ
                    </Button>



                    <Grid container>
                      <Grid item xs>

                      </Grid>
                      <Grid item>

                      </Grid>
                    </Grid>
                    <Copyright sx={ { mt: 5 } } />
                  </Box>
                </> }
            </Box>
          </Grid>

        </Grid>
      </ThemeProvider >




    </>

  );
}

export default App;
